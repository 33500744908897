<template>
  <div>
    <div
      :id="swiperId"
      ref="mainSwiperRef"
      :dir="localeProperties.dir"
      class="swiper"
    >
      <div
        v-if="title"
        class="absolute z-10 -top-1 sm:top-0 sm:top-1 left-1/2 -translate-x-1/2 e_store-title text-center whitespace-nowrap"
      >
        <EStoreLink v-if="routeTitle" :to="routeTitle" :aria-label="title">
          <span>{{ title }}</span>
        </EStoreLink>
        <span v-else>{{ title }}</span>
      </div>

      <EStoreLink
        v-if="viewMore"
        class="absolute z-10 top-0 sm:top-3 sm:ltr:right-28 sm:rtl:left-28 hover:underline hover:text-nav_color font-bold"
        :to="viewMore"
        :aria-label="t('view_more')"
      >
        <span class="text-xs sm:text-base">{{ t('view_more') }}</span>
      </EStoreLink>
      <div v-if="title" :class="{ 'hidden sm:block pt-12': autoSlides }"></div>
      <div v-if="title" :class="{ 'block sm:hidden pt-5': autoSlides }"></div>
      <div class="swiper-wrapper max-w-fit mx-auto">
        <div
          v-for="(item, index) in items"
          :key="item.id"
          class="swiper-slide"
          :class="{ auto: autoSlides }"
        >
          <slot name="slide" :item="item" :index="index" />
        </div>
      </div>

      <div ref="paginationRef" class="swiper-pagination main-pagination"></div>

      <div
        v-if="swiperNavigation"
        ref="prevMainRef"
        class="swiper-button-prev main-prev 'bg-button_color'"
        :class="[
          { auto: autoSlides }
        ]"
      ></div>

      <div
        v-if="swiperNavigation"
        ref="nextMainRef"
        class="swiper-button-next main-next bg-button_color"
        :class="[
          { auto: autoSlides }
        ]"
      ></div>
    </div>

    <div
      v-if="thumb"
      ref="thumbSwiperRef"
      :dir="localeProperties.dir"
      class="swiper"
    >
      <div class="swiper-wrapper thumb">
        <div
          v-for="(item, index) in items"
          :key="item.id"
          class="swiper-slide auto"
          :class="thumbSlideClass"
        >
          <slot name="thumb" :item="item" :index="index" />
        </div>
      </div>

      <div
        ref="prevThumbRef"
        class="swiper-button-prev thumb-prev bg-button_color"
      ></div>
      <div
        ref="nextThumbRef"
        class="swiper-button-next thumb-next bg-button_color"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Swiper from 'swiper/bundle'
import type { SwiperOptions } from 'swiper'

import 'swiper/css'
import 'swiper/css/bundle'

const props = withDefaults(
  defineProps<{
    items: any[]
    title?: string
    routeTitle?: object
    viewMore?: any
    selectedSlide?: number
    swiperId?: string
    swiperPagination?: boolean
    swiperNavigation?: boolean
    autoSlides?: boolean
    autoPlay?: boolean
    swiperOptions?: SwiperOptions
    thumb?: boolean
    thumbOptions?: SwiperOptions
    thumbSlideClass?: string
    initialSlide?: number
  }>(),
  {
    autoPlay: true
  }
)
const emits = defineEmits(['slide-change'])

const { t, localeProperties } = useI18n()

const mainSwiperRef = ref<HTMLElement | null>(null)
const thumbSwiperRef = ref<HTMLElement | null>(null)
const paginationRef = ref<HTMLElement | null>(null)
const nextThumbRef = ref<HTMLElement | null>(null)
const prevThumbRef = ref<HTMLElement | null>(null)
const prevMainRef = ref<HTMLElement | null>(null)
const nextMainRef = ref<HTMLElement | null>(null)
const swiper = ref<Swiper | null>(null)
const thumbs = ref<Swiper | null>(null)

onMounted(() => {
  if (mainSwiperRef.value) {
    swiper.value = new Swiper(mainSwiperRef.value, {
      autoplay: props.autoPlay
        ? {
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            delay: props.autoSlides ? 2000 : 4000
          }
        : undefined,
      speed: 1000,
      nested: true,
      loop: !props.autoSlides,

      pagination: props.swiperPagination
        ? { el: paginationRef.value, clickable: true }
        : {},

      navigation: props.swiperNavigation
        ? {
            nextEl: nextMainRef.value,
            prevEl: prevMainRef.value
          }
        : {},

      slidesPerView: props.autoSlides ? 'auto' : 1,

      initialSlide: props.initialSlide || 0,

      ...(props.swiperOptions || {})
    })

    swiper.value.on('realIndexChange', (e) => {
      emits('slide-change', e.realIndex)

      if (thumbs.value) {
        thumbs.value.slideTo(e.realIndex)
      }
    })

    if (Number.isSafeInteger(props.selectedSlide)) {
      swiper.value.slideTo(props.selectedSlide)
      autoplayController(props.selectedSlide)
    }
  }

  if (thumbSwiperRef.value) {
    thumbs.value = new Swiper(thumbSwiperRef.value, {
      navigation: {
        nextEl: prevThumbRef.value,
        prevEl: nextThumbRef.value
      },

      centeredSlides: true,
      slidesPerView: 'auto',
      slideActiveClass: 'border-nav_color',

      ...(props.thumbOptions || {})
    })

    thumbs.value.on('click', (s) => {
      if (props.autoSlides) {
        swiper.value?.slideTo(s.clickedIndex)
      } else {
        swiper.value?.slideToLoop(s.clickedIndex)
      }
    })
  }
})

watch(() => props.selectedSlide, autoplayController)

function autoplayController(index?: number) {
  if (index) {
    swiper.value?.autoplay.stop()
  } else {
    swiper.value?.autoplay.start()
  }
}
</script>

<style>
.swiper-slide {
  height: auto !important;
}
.swiper-slide.auto {
  flex-shrink: 1 !important;
  min-width: fit-content;
}

/* ************** THUMBS NAVIGATION ************** */

.swiper-button-prev.thumb-prev,
.swiper-button-next.thumb-next {
  @apply hidden sm:flex  p-1 rounded;
}

.swiper-button-prev.thumb-prev::after,
.swiper-button-next.thumb-next::after {
  @apply text-text_alt_color text-base;
}

/* ************** NAVIGATION ONE SLIDE ************** */

.swiper-button-prev.main-prev,
.swiper-button-next.main-next {
  @apply hidden sm:flex  p-1 rounded;
}

.swiper-button-prev.main-prev::after,
.swiper-button-next.main-next::after {
  @apply text-text_alt_color text-base;
}

/* ************** NAVIGATION MULTI SLIDES ************** */

.swiper-button-prev.main-prev.auto,
.swiper-button-next.main-next.auto {
  @apply absolute -top-4 translate-y-full  w-10 h-10 rounded-full;
}

.swiper-button-prev.main-prev.auto {
  @apply ltr:left-full ltr:-translate-x-24 rtl:right-full rtl:translate-x-24;
}

.swiper-button-prev.main-prev.auto::after,
.swiper-button-next.main-next.auto::after {
  @apply text-text_alt_color text-base;
}

/* ******************** PAGINATION ******************** */

.swiper-pagination.main-pagination {
  @apply bottom-4;
}
.swiper-pagination.main-pagination > .swiper-pagination-bullet {
  @apply bg-bg_color opacity-100 shadow;
}
.swiper-pagination.main-pagination > .swiper-pagination-bullet-active {
  @apply w-10 bg-nav_color rounded-full;
}
</style>
